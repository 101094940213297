@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/SourceSansPro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/SourceSansPro-Semibold.woff2') format('woff2');
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #4f4e4e;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  text-decoration: underline;
  color: #F6001D;
}

input:focus {
  outline: none;
}

img {
  border-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 1.2;
  color: #121f3e;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

.WidgetContainer {
  display: block;
  width: 100%;
  height: 100%;
}
